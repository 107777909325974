<template>
  <b-card
    no-body
    class="box-shadow-0"
    :class="{'open': visible}"
    :disabled="disabled"
    @mouseenter="collapseOpen"
    @mouseleave="collapseClose"
  >
    <b-card-header
      v-touch:start="onStart"
      v-touch:tap="onTap"
      v-touch:longtap="onLongTap"
      v-touch:moving="onEnd"
      v-touch:end="onEnd"
      :class="{'collapsed': !visible, 'hold': holdOn}"
      :aria-expanded="visible ? 'true' : 'false'"
      :aria-controls="collapseItemID"
      role="tab"
      data-toggle="collapse"
      style="border-radius: 0"
      @click="updateVisible(!visible)"
    >
      <slot name="header">
        <span class="lead collapse-title">{{ title }}</span>
      </slot>
    </b-card-header>

    <b-collapse
      :id="collapseItemID"
      v-model="visible"
      :accordion="accordion"
      role="tabpanel"
    >
      <b-card-body>
        <slot />
      </b-card-body>
    </b-collapse>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BCollapse,
} from 'bootstrap-vue'
import { v4 as uuidv4 } from 'uuid'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BCollapse,
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      visible: false,
      collapseItemID: '',
      openOnHover: this.$parent.hover,
      holdOn: false,
    }
  },
  computed: {
    accordion() {
      return this.$parent.accordion ? `accordion-${this.$parent.collapseID}` : null
    },
  },
  created() {
    this.collapseItemID = uuidv4()
    this.visible = this.isVisible
  },
  methods: {
    updateVisible(val = true) {
      this.visible = val
      this.$emit('visible', val)
    },
    collapseOpen() {
      if (this.openOnHover) this.updateVisible(true)
    },
    collapseClose() {
      if (this.openOnHover) this.updateVisible(false)
    },
    onStart(e) {
      if (this.disable) return
      this.holdOn = true
      this.$emit('actionStart', e)
    },
    async onTap(e) {
      if (this.disable) return

      this.holdOn = true
      this.$emit('action', e)
      await window.sleep(200)
      this.holdOn = false
    },
    async onLongTap(e) {
      if (this.disable) return

      this.holdOn = true
      this.$emit('actionLongTap', e)
      await window.sleep(200)
      this.holdOn = false
    },
    onEnd(e) {
      this.$nextTick(async () => {
        await window.sleep(200)
        this.holdOn = false
        this.$emit('actionEnd', e)
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/base/bootstrap-extended/include';
@import '@core/scss/base/components/include';

.hold {
  background-color: darken($white, 10) !important;
}
</style>
