<template>
  <button
    v-touch:start="onStart"
    v-touch:tap="onTap"
    v-touch:longtap="onLongTap"
    v-touch:moving="onEnd"
    v-touch:end="onEnd"
    :class="[{'hold': holdOn, 'disabled': disabled}, color, size]"
  >
    <slot />
  </button>
</template>

<script>
export default {
  components: {},
  props: {
    size: {
      type: String,
      default: 'md',
    },
    color: {
      type: String,
      default: 'primary',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      holdOn: false,
    }
  },
  methods: {
    onStart(e) {
      if (this.disabled) return
      this.holdOn = true
      this.$emit('actionStart', e)
    },
    async onTap(e) {
      if (this.disabled) return

      this.holdOn = true
      this.$emit('action', e)
      await window.sleep(200)
      this.holdOn = false
    },
    async onLongTap(e) {
      if (this.disabled) return

      this.holdOn = true
      this.$emit('actionLongTap', e)
      await window.sleep(200)
      this.holdOn = false
    },
    onEnd(e) {
      this.$nextTick(async () => {
        await window.sleep(200)
        this.holdOn = false
        this.$emit('actionEnd', e)
      })
    },
  },
}
</script>

<style scoped lang="scss">
@use "sass:map";
@import '@core/scss/base/bootstrap-extended/_include';
@import '@core/scss/base/components/_include';

button {
  box-shadow: 0 3px 13px rgb(0 0 0 / 5%);
  background-color: #ffffff;
  color: $gray-800;
  text-align: center;
  border: none;
  padding: 0;
  border-radius: 0.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;

  &.sm {
    font-size: 1rem;
    padding: 10px 20px;
  }

  &.md {
    height: 39px;
    width: 39px;
    font-size: 1rem;
  }

  &.lg {
    font-size: 1.3rem;
    padding: 15px 25px;
  }

  &.xl {
    font-size: 1.6rem;
    padding: 15px 30px;
  }

  @each $name, $value in $theme-colors {
    &.#{$name} {
      background-color: $value;
      color: map.get($text-color-with-background-color, $name);

      &.hold {
        background-color: darken($value, 10) !important;
      }

      &.disabled {
        opacity: 0.6 !important;;
      }
    }
  }
}
</style>
